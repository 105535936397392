'use strict';

var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var Caml_option = require("bs-platform/lib/js/caml_option.js");

function throttle(fn, time) {
  var last = {
    contents: undefined
  };
  return (function (v) {
      var match = last.contents;
      if (match !== undefined) {
        clearTimeout(Caml_option.valFromOption(match));
      }
      last.contents = Caml_option.some(setTimeout((function (param) {
                  return Curry._1(fn, v);
                }), time));
      return /* () */0;
    });
}

var context = React.createContext(1024);

function makeProps(value, children, param) {
  return {
          value: value,
          children: children
        };
}

var make = context.Provider;

var ContextProvider = {
  makeProps: makeProps,
  make: make
};

function reducer(_state, action) {
  return action[0];
}

function WindowDimensions$Root(Props) {
  var children = Props.children;
  var initialWindowWidth = React.useMemo((function () {
          return window.innerWidth;
        }), /* array */[]);
  var match = React.useReducer(reducer, initialWindowWidth);
  var dispatch = match[1];
  React.useEffect((function () {
          var onResize = throttle((function (param) {
                  return Curry._1(dispatch, /* Update */[window.innerWidth]);
                }), 100);
          window.addEventListener("resize", onResize);
          return (function (param) {
                    window.removeEventListener("resize", onResize);
                    return /* () */0;
                  });
        }), ([]));
  return React.createElement(make, makeProps(match[0], children, /* () */0));
}

var Root = {
  reducer: reducer,
  make: WindowDimensions$Root
};

function WindowDimensions$Consumer(Props) {
  var render = Props.render;
  return Curry._1(render, React.useContext(context));
}

var Consumer = {
  make: WindowDimensions$Consumer
};

var root = WindowDimensions$Root;

exports.throttle = throttle;
exports.context = context;
exports.ContextProvider = ContextProvider;
exports.Root = Root;
exports.root = root;
exports.Consumer = Consumer;
/* context Not a pure module */
