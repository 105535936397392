/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// You can delete this file if you're not using it

import "./src/global.css"
import "./src/prism.css"
// import "prismjs/themes/prism-solarizedlight.css"
import * as React from "react"
import {
  container as LayerContainer,
  provider as LayerProvider,
} from "./src/components/Layer.bs"
import { root as WindowDimensionsRoot } from "./src/components/WindowDimensions.bs"

export const wrapPageElement = ({ element, props }) => {
  return (
    <WindowDimensionsRoot>
      <LayerProvider>
        {element}
        <LayerContainer />
      </LayerProvider>
    </WindowDimensionsRoot>
  )
}
