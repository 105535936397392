// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-components-post-page-bs-js": () => import("../src/components/PostPage.bs.js" /* webpackChunkName: "component---src-components-post-page-bs-js" */),
  "component---src-components-album-page-bs-js": () => import("../src/components/AlbumPage.bs.js" /* webpackChunkName: "component---src-components-album-page-bs-js" */),
  "component---src-pages-404-js": () => import("../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-posts-js": () => import("../src/pages/posts.js" /* webpackChunkName: "component---src-pages-posts-js" */),
  "component---src-pages-about-page-bs-js": () => import("../src/pages/AboutPage.bs.js" /* webpackChunkName: "component---src-pages-about-page-bs-js" */),
  "component---src-pages-drawings-page-bs-js": () => import("../src/pages/DrawingsPage.bs.js" /* webpackChunkName: "component---src-pages-drawings-page-bs-js" */),
  "component---src-pages-home-page-bs-js": () => import("../src/pages/HomePage.bs.js" /* webpackChunkName: "component---src-pages-home-page-bs-js" */),
  "component---src-pages-log-page-bs-js": () => import("../src/pages/LogPage.bs.js" /* webpackChunkName: "component---src-pages-log-page-bs-js" */),
  "component---src-pages-photos-page-bs-js": () => import("../src/pages/PhotosPage.bs.js" /* webpackChunkName: "component---src-pages-photos-page-bs-js" */)
}

