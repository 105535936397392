'use strict';

var Css = require("bs-css/src/Css.js");
var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var ReactDom = require("react-dom");
var Caml_array = require("bs-platform/lib/js/caml_array.js");
var Belt_Option = require("bs-platform/lib/js/belt_Option.js");
var Caml_option = require("bs-platform/lib/js/caml_option.js");

var container = Css.style(/* :: */[
      Css.position(/* absolute */-1013592457),
      /* :: */[
        Css.top(/* zero */-789508312),
        /* :: */[
          Css.left(/* zero */-789508312),
          /* :: */[
            Css.width(/* `percent */[
                  -119887163,
                  100
                ]),
            /* :: */[
              Css.zIndex(1),
              /* [] */0
            ]
          ]
        ]
      ]
    ]);

var Styles = {
  container: container
};

var context = React.createContext({
      container: undefined,
      setContainer: (function (param) {
          return /* () */0;
        })
    });

function makeProps(value, children, param) {
  return {
          value: value,
          children: children
        };
}

var make = context.Provider;

var ContextProvider = {
  makeProps: makeProps,
  make: make
};

var layers = {
  contents: /* array */[]
};

function Layer(Props) {
  var children = Props.children;
  var onKeyPress = Props.onKeyPress;
  var layerContainer = Belt_Option.getExn(React.useContext(context).container);
  var layer = React.useRef({
        onKeyPress: onKeyPress
      });
  layer.current = {
    onKeyPress: onKeyPress
  };
  React.useLayoutEffect((function () {
          layers.contents = layers.contents.concat(/* array */[layer]);
          return (function (param) {
                    layers.contents = layers.contents.filter((function (l) {
                            return l !== layer;
                          }));
                    return /* () */0;
                  });
        }), ([]));
  return ReactDom.createPortal(children, layerContainer);
}

function Layer$provider(Props) {
  var children = Props.children;
  var match = React.useState((function () {
          return ;
        }));
  var setContainer = match[1];
  var setContainerCallback = React.useCallback((function (element) {
          return Curry._1(setContainer, (function (param) {
                        return Caml_option.some(element);
                      }));
        }), ([]));
  return React.createElement(make, makeProps({
                  container: match[0],
                  setContainer: setContainerCallback
                }, children, /* () */0));
}

function Layer$container(Props) {
  var domRef = React.useRef(null);
  var setContainer = React.useContext(context).setContainer;
  React.useLayoutEffect((function () {
          var element = Belt_Option.getExn(Caml_option.nullable_to_opt(domRef.current));
          Curry._1(setContainer, element);
          var onKeyPress = function (e) {
            if (layers.contents.length > 0) {
              var match = Caml_array.caml_array_get(layers.contents, layers.contents.length - 1 | 0).current;
              var onKeyPress$1 = match.onKeyPress;
              if (onKeyPress$1 !== undefined) {
                return Curry._1(onKeyPress$1, e);
              } else {
                return /* () */0;
              }
            } else {
              return 0;
            }
          };
          window.addEventListener("keydown", onKeyPress);
          return (function (param) {
                    return /* () */0;
                  });
        }), ([]));
  return React.createElement("div", {
              ref: domRef,
              className: container
            });
}

var make$1 = Layer;

var provider = Layer$provider;

var container$1 = Layer$container;

exports.Styles = Styles;
exports.context = context;
exports.ContextProvider = ContextProvider;
exports.layers = layers;
exports.make = make$1;
exports.provider = provider;
exports.container = container$1;
/* container Not a pure module */
